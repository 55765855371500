<template>
    <div id="cliniques-entry" class="page-wrapper" v-if="pageIsLoaded">
        <!-- Home Slider -->
        <section class="clinique-slider extends home-slider">
            <div class="main-carousel">
                <div class="carousel-cell" v-for="(item, i) in content.slider.images" :key="i">
                    <div class="overlay-effect"></div>
                    <img :src="item.url" :alt="item.titre" :title="item.titre" />
                </div>
            </div>
        </section>

        <!-- Infos -->
        <div class="infos-text">
            <div class="section-heading-n-btn">
                <h2 class="regular-title" data-inview="fadeInUp" data-delay="100">
                    {{ content.title }}
                </h2>
            </div>

            <div class="infos-text-grid">
                <div class="col" data-inview="fadeInUp" data-delay="100">
                    <address>
                        <svg
                            width="36"
                            height="46"
                            viewBox="0 0 36 46"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M33.9809 9.70022C31.2582 4.32373 25.9915 0.705337 20.005 0.0995258C18.6712 -0.0331753 17.3287 -0.0331753 15.9971 0.0995258C10.0084 0.707715 4.74206 4.32373 2.01902 9.69796C0.331939 13.0729 -0.319454 16.8726 0.146465 20.6167C0.612383 24.3609 2.17357 27.8842 4.63573 30.7417L16.6353 45.3542C16.971 45.7634 17.4722 46 18 46C18.5278 46 19.029 45.7634 19.3647 45.3542L31.3643 30.7417C33.8264 27.8843 35.3876 24.3609 35.8535 20.6167C36.3195 16.8726 35.6681 13.0752 33.981 9.70022H33.9809ZM17.9999 24.7679C15.1425 24.7679 12.5655 23.0429 11.4724 20.4C10.3771 17.7549 10.9822 14.7093 13.0027 12.6858C15.0231 10.6622 18.0638 10.0562 20.7048 11.1532C23.3437 12.2479 25.066 14.8289 25.066 17.6908C25.0616 21.5965 21.8994 24.7635 17.9998 24.7681L17.9999 24.7679Z"
                                fill="#565B6E"
                            />
                        </svg>
                        <p class="small-text">
                            <span v-html="content.adresse.long"></span>
                        </p>
                    </address>
                </div>
                <div class="col" data-inview="fadeInUp" data-delay="200">
                    <a :href="'tel:' + content.telephone">
                        <svg
                            height="35"
                            viewBox="0 0 27 46"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M3.53288 0H23.4671C25.4025 0 27 1.58842 27 3.51287V42.4871C27 44.4116 25.4025 46 23.4671 46H3.53288C1.59747 46 0 44.4116 0 42.4871V3.51287C0 1.58842 1.59747 0 3.53288 0V0ZM11.1027 42.8212C10.5985 42.8212 10.2624 42.487 10.2624 41.9857C10.2624 41.4844 10.5985 41.1501 11.1027 41.1501H15.9819C16.403 41.1501 16.8223 41.4844 16.8223 41.9857C16.8223 42.487 16.4012 42.8212 15.9819 42.8212H11.1027ZM2.35459 5.68696V37.9715H24.7282V5.68696H2.35459Z"
                                fill="#565B6E"
                            />
                        </svg>
                        <span class="small-text">{{ content.telephone }}</span>
                    </a>
                </div>
                <div class="col" data-inview="fadeInUp" data-delay="300">
                    <a :href="'mailto:' + content.courriel">
                        <svg
                            height="22"
                            viewBox="0 0 46 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.09091 0C0.959675 0 0 0.949603 0 2.06897V27.931C0 29.0504 0.959675 30 2.09091 30H43.9091C45.0403 30 46 29.0504 46 27.931V2.06897C46 0.949603 45.0403 0 43.9091 0H2.09091ZM3.13636 3.10345H42.8636V3.86317L23 18.2327L3.13636 3.86317V3.10345ZM3.13636 7.7101L22.069 21.417C22.6224 21.8211 23.3779 21.8211 23.9312 21.417L42.8639 7.7101V26.8967H3.13662L3.13636 7.7101Z"
                                fill="#565B6E"
                            />
                        </svg>
                        <span class="small-text">{{ content.courriel }}</span>
                    </a>
                </div>
                <div class="col" data-inview="fadeInUp" data-delay="400">
                    <a
                        href="#"
                        class="site-btn alternate"
                        title="Prendre rendez-vous"
                        @click.prevent="togglePanel('rendez-vous')"
                        >{{ $t('Prendre rendez-vous') }}</a
                    >
                </div>
            </div>
        </div>

        <!-- Map -->
        <section class="contact-map">
            <div class="col col-hours">
                <p class="regular-text bold title">{{ $t('Heures d’ouverture') }}</p>
                <div class="hours">
                    <p class="small-text row" :class="{ 'is-active': isToday(jrs) }" v-for="(hrs, jrs) in content.heures" :key="jrs">
                        <span style="text-transform: capitalize">{{ $t(jrs) }}</span>
                        <span>{{ hrs }}</span>
                    </p>
                </div>
                <p class="regular-text bold title">{{ $t('Modes de paiements acceptés') }}</p>
                <div class="icons">
                    <span class="icon" v-if="content.modesDePaiments.comptant">
                        <img
                            height="70"
                            src="@/assets/img/cash.png"
                            alt="Vise"
                            v-if="content.modesDePaiments.comptant"
                        />
                        <span class="label">{{ $t('Comptant') }}</span>
                    </span>
                    <span class="icon" v-if="content.modesDePaiments.debit">
                        <img
                            height="70"
                            src="@/assets/img/debit.png"
                            alt="Vise"
                            v-if="content.modesDePaiments.debit"
                        />
                        <span class="label">{{ $t('Débit') }}</span>
                    </span>
                    <span class="icon"  v-if="content.modesDePaiments.credit">
                        <img
                            height="70"
                            src="@/assets/img/credit.png"
                            alt="Vise"
                            v-if="content.modesDePaiments.credit"
                        />
                        <span class="label">{{ $t('Crédit') }}</span>
                    </span>
                    <span class="icon" v-if="content.modesDePaiments.accordD">
                        <img
                            height="70"
                            src="@/assets/img/accordd.png"
                            alt="Vise"
                            v-if="content.modesDePaiments.accordD"
                        />
                        <span class="label">{{ $t('Accord D') }}</span>
                    </span>
                    <span class="icon" v-if="content.modesDePaiments.dentoplan">
                        <img
                            height="70"
                            src="@/assets/img/dentoplan.jpg"
                            alt="Vise"
                            v-if="content.modesDePaiments.dentoplan"
                        />
                        <span class="label">{{ $t('Dentoplan') }}</span>
                    </span>
                    <span class="icon" v-if="content.modesDePaiments.flexitiFinancial">
                        <img
                            height="70"
                            src="@/assets/img/flexiti.jpg"
                            alt="Vise"
                            v-if="content.modesDePaiments.flexitiFinancial"
                        />
                        <span class="label">{{ $t('FlexitiFinancial') }}</span>
                    </span>
                </div>
            </div>
            <div class="col col-map" data-inview="fadeInUp" data-delay="200">
                <div id="map"></div>
            </div>
        </section>

        <!-- Notre equipe -->
        <section class="notre-equipe">
            <div class="col col-content">
                <h2 class="small-title" data-inview="fadeInUp" data-delay="100">
                    {{ content.equipe.titre }}
                </h2>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    {{ content.equipe.texte }}
                </p>
                <div data-inview="fadeInUp" data-delay="400">
                    <a
                        :href="content.equipe.boutonUrl[$route.meta.lang]"
                        class="site-btn alternate"
                        :title="content.equipe.bouton.customText"
                        >{{ content.equipe.bouton.customText }}</a
                    >
                </div>
            </div>
            <div class="col col-slider">
                <div class="grid carousel-team" data-inview="fadeInUp" data-delay="600">
                    <router-link
                        :to="item.poste === 'Dentiste' || item.poste === 'Dentist' ? item.jsonUrl : ''"
                        class="team-thumb carousel-cell"
                        title=""
                        v-for="(item, i) in globals.equipeMembres.data"
                        :key="i"
                    >
                        <div class="img-wrap">
                            <img :src="item.image.url" :alt="item.image.titre" />
                            <svg
                                class="plus"
                                height="45"
                                viewBox="0 0 60 60"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                v-if="item.poste === 'Dentiste' || item.poste === 'Dentist'"
                            >
                                <circle cx="30" cy="30" r="30" fill="white" />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M30 40C30.9266 40 31.6931 39.2335 31.6931 38.3069V31.6931H38.3069C39.2335 31.6931 40 30.9266 40 30C40 29.0734 39.2335 28.3069 38.3069 28.3069H31.6931V21.6931C31.6931 20.7665 30.9266 20 30 20C29.0734 20 28.3069 20.7665 28.3069 21.6931V28.3069H21.6931C20.7665 28.3069 20 29.0734 20 30C20 30.9266 20.7665 31.6931 21.6931 31.6931H28.3069V38.3069C28.3069 39.2335 29.0734 40 30 40V40Z"
                                    fill="#565B6E"
                                />
                            </svg>
                        </div>
                        <div class="content">
                            <p class="regular-text title">{{ item.title }}</p>
                            <p class="small-text text">{{ item.poste }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </section>

        <img src="@/assets/img/map-pointer.svg" id="map-pointer" />
    </div>
    <PageLoader v-else />
</template>

<script>
import Flickity from 'flickity'
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapState, mapActions, mapGetters } from 'vuex'
import PageLoader from '@/components/PageLoader.vue'

export default {
    name: 'CliniquesEntry',

    data() {
        return {
            mainCarouselFlk: null,
            teamCarouselFlk: null,
        }
    },

    components: {
        PageLoader,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page load buffer
                    setTimeout(() => {
                        initInViewAnimations()
                    }, 155)

                    // Page load buffer
                    setTimeout(() => {
                        const mainCarousel = document.querySelector('.main-carousel')
                        this.mainCarouselFlk = new Flickity(mainCarousel, {
                            cellAlign: 'center',
                            wrapAround: true,
                            lazyLoad: true,
                            imagesLoaded: true,
                            prevNextButtons: true,
                            pageDots: false,
                        })

                        const teamCarousel = document.querySelector('.carousel-team')
                        this.teamCarouselFlk = new Flickity(teamCarousel, {
                            cellAlign: 'left',
                            wrapAround: true,
                            lazyLoad: true,
                            imagesLoaded: true,
                            prevNextButtons: true,
                            pageDots: false,
                        })

                        setTimeout(() => {
                            this.mainCarouselFlk.resize()
                            this.teamCarouselFlk.resize()

                            setTimeout(() => {
                                this.mainCarouselFlk.resize()
                                this.teamCarouselFlk.resize()
                            }, 1200)
                        }, 1200)

                        // Google Map Init
                        const scriptId = 'mapInitEl'
                        const mapAlreadyAttached = !!document.getElementById(scriptId)

                        if (mapAlreadyAttached) {
                            if (window.google) {
                                this.initGoogleMap()
                            }
                        } else {
                            window.mapApiInitialized = this.initGoogleMap

                            const script = document.createElement('script')
                            script.id = scriptId
                            script.src =
                                'https://maps.googleapis.com/maps/api/js?key=AIzaSyA_iUWeCzI-ONlOIDfx18r3oVDKWYWhZDU&libraries=places,geometry&callback=mapApiInitialized'
                            document.body.appendChild(script)
                        }
                    }, 755)
                }
            },
            immediate: true,
        },
    },

    methods: {
        isToday(jrs) {
            const check = {
                dimanche: 7,
                jeudi: 4,
                lundi: 1,
                mardi: 2,
                mercredi: 3,
                samedi: 6,
                vendredi: 5,

            }
            return new Date().getDay() === check[jrs]
        },
        initGoogleMap() {
            let position = new google.maps.LatLng(45.30654, -73.23026)
            if (window.location.href.includes('saint-jean')) {
                position = new google.maps.LatLng(45.298720, -73.266170)
            }

            /* global google */
            const mapOptions = {
                // How zoomed in you want the map to start at (always required)
                zoom: 15,

                // The latitude and longitude to center the map (always required)
                center: position,

                // How you would like to style the map.
                // This is where you would paste any style found on Snazzy Maps.
                styles: [
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
                    },
                    {
                        featureType: 'landscape',
                        elementType: 'geometry',
                        stylers: [{ color: '#f5f5f5' }, { lightness: 20 }],
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.fill',
                        stylers: [{ color: '#ffffff' }, { lightness: 17 }],
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
                    },
                    {
                        featureType: 'road.arterial',
                        elementType: 'geometry',
                        stylers: [{ color: '#ffffff' }, { lightness: 18 }],
                    },
                    {
                        featureType: 'road.local',
                        elementType: 'geometry',
                        stylers: [{ color: '#ffffff' }, { lightness: 16 }],
                    },
                    {
                        featureType: 'poi',
                        elementType: 'geometry',
                        stylers: [{ color: '#f5f5f5' }, { lightness: 21 }],
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{ color: '#dedede' }, { lightness: 21 }],
                    },
                    {
                        elementType: 'labels.text.stroke',
                        stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }],
                    },
                    {
                        elementType: 'labels.text.fill',
                        stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }],
                    },
                    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry.fill',
                        stylers: [{ color: '#fefefe' }, { lightness: 20 }],
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry.stroke',
                        stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
                    },
                ],
            }

            // Get the HTML DOM element that will contain your map
            // We are using a div with id="map" seen below in the <body>
            const mapElement = document.getElementById('map')

            // Create the Google Map using our element and options defined above
            const map = new google.maps.Map(mapElement, mapOptions)

            // Let's also add a marker while we're at it
            new google.maps.Marker({
                position: position,
                map,
                title: 'Votre Dentisterie',
                icon: document.querySelector('#map-pointer').getAttribute('src'),
            })
        },
        ...mapActions(['togglePanel']),
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-wrapper {
    min-height: 90vh;
}

img#map-pointer {
    position: absolute;
    top: -5555px;
    left: -5555px;
}
</style>
